section.calendar {
}

.fc {
  .fc-button-group,
  .fc-button,
  .fc-button-primary {
    background-color: $dk_light_black !important;
    border-radius: 15px !important;
    width: fit-content !important;
    border: 1px solid $dk_light_black !important;
  }

  .fc-today-button:disabled,
  .fc-today-button[disabled],
  .fc-today-button[disabled="disabled"],
  .fc-button:disabled,
  .fc-button-primary:disabled,
  button:disabled {
    border: 2px solid $dk_grey !important;
    background-color: $dk_light_black !important;
  }

  .fc-col-header {
    background-color: white;
  }

  .fc-toolbar-title {
    font-weight: bold;
  }

  a {
    color: $dk_light_black;
  }

  .fc-event {
    cursor: pointer;
    font-weight: 400 !important;
    font-size: 90%;
  }

  .modal--calendar {
    word-wrap: break-word;
  }

  .event--al {
    background-color: #6a0dad;
    border-color: #6a0dad;
    color: white;
  }

  .event--holiday {
    background-color: #f2a7b5;
    border-color: #f2a7b5;
    color: white;
  }

  .event--bd {
    background-color: #ffc0cb;
    border-color: #ffc0cb;
    color: white;
  }

  .event--single {
    // background-color: $dk_light_black;
    border-color: $dk_light_black;
  }

  .event-meeting {
    //background-color: $dk-red;
    border-color: $dk-red;
  }
}
