@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

@import "./colours";

@import "./typography";
@import "./variables";

// Override Bootstrap defaults with above imports
@import "./node_modules/bootstrap/scss/bootstrap";

@import "./layouts/main-layout";

@import "./pages/homepage/homepage";
@import "./pages/homepage/welcome-user";

@import "./pages/authentication/authentication";
@import "./pages/calendar/calendar";
@import "./pages/calendar/attendees-filter";

@import "./pages/reports/reports";

@import "./components/buttons";
@import "./components/sidebar";
@import "./components/topbar";
@import "./components/card";
@import "./components/table";

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: "Poppins", sans-serif;

  background-color: $dk_grey_4;
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 6px !important;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}
