section.authentication {
  .card {
    box-shadow: 0px 13px 21px -5px rgba(49, 48, 48, 0.3);
    width: 30rem;
    border-radius: 20px;
  }

  .card-header {
    background-color: $dk_light_black;
  }
}

.ceres-icon {
  height: 4.5rem;
  width: 4.5rem;
  margin-top: -4rem;
  padding: 3%;
  background-color: $dk_grey_4;
}

.avatar-title {
  align-items: center;
  background-color: red;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.login--google {
  padding: 0.6rem 7rem !important;
  border-radius: 30px !important;
  box-shadow: none !important;
  border-width: 1.9px !important;
  border-color: $dk_light_black !important;
  color: $dk_light_black !important;
  transition: all ease 0.5s;

  &:hover {
    background-color: $dk_light_black !important;
    color: white !important;
  }
}
