section.topbar {
  height: auto;
  background-color: $dk_white;
  position: fixed;
  top: 0;
  right: 0;
  left: $sidebar-width;
  z-index: 1000;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);

  color: $dk_light_black;

  &.collapsed {
    left: $sidebar-width-collapsed;
  }

  .menu--toggle {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .topbar--profile--icon {
    width: 2.3rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-self: center;
    border-image-width: 10px;
  }

  .topbar--left {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 1%;
  }

  .topbar--right {
    padding-right: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
  }

  .topbar--right > li,
  .topbar--left > li {
    display: inline-block;
  }

  .date-selector {
    width: 20rem;
  }

  .fullscreen--toggle {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    .fullscreen--toggle--icon {
      font-size: 1.2rem;
    }
  }

  .dropdown-toggle,
  .dropdown-toggle:focus,
  .dropdown-toggle:active,
  #dropdown--profile,
  .notification--toggle {
    background-color: transparent;
    border-color: #fff;
    border-style: solid;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    box-shadow: none;
    outline: none !important;
    padding: 0;
    margin: 0;
  }

  #dropdown--notification {
    color: $dk_light_black;
    font-size: 1.2rem;
    outline: none;
    padding: 0;
    margin: 0;
  }

  #dropdown--profile::after,
  #dropdown--notification::after {
    display: none;
  }

  .topbar--date-selector-wapper.hidden {
    .topbar--date-selector,
    .rdrDateRangePickerWrapper {
      display: none !important;
    }
  }

  .topbar--date-selector,
  .rdrDateRangePickerWrapper {
    position: absolute !important;
    display: fixed !important;
    background-color: green !important;
  }
}
