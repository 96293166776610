section.welcome-user {
  .card {
    .card-header {
      padding-top: 0;
      background-color: $dk_light_black;
      color: white;
      padding-bottom: 2rem;
    }

    .card-body {
      padding-top: 5%;
    }

    .welcome-user--photo {
      height: 5rem;
      width: 5rem;
      margin-top: -4rem;
      padding: 1.5%;
      background-color: $dk_grey_4;
      border-radius: 50%;
    }

    .welcome-user--details {
      margin: 6% 0;
      padding: 2%;
    }

    .profile-link {
      overflow-x: hidden;
      position: absolute;
      right: 0rem;
      bottom: 1rem;
      border-color: $dk_red;
      border-width: 1px;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;

      border-style: solid;
      border-right: 0;
      color: $dk_red;
      padding: 0.4rem 1rem;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $dk_red;
        color: white;
        text-decoration: none;
      }
    }
  }
}
