section.sidebar {
  position: fixed;
  background-color: $dk_light_black;
  height: auto;
  min-height: 100vh;
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: $sidebar-width;
  padding: 0.2%;
  overflow-y: auto;
  top: 0;
  bottom: 0;

  &.collapsed {
    width: $sidebar-width-collapsed;
    display: none;
  }

  .sidebar--logo {
    width: 100%;
    padding: 10%;
  }

  .sidebar--nav {
    ul {
      list-style-type: none;
      padding: 0;

      .menu-title {
        padding: 12px 20px !important;
        letter-spacing: 0.05em;
        pointer-events: none;
        cursor: default;
        font-size: 11px;
        text-transform: uppercase;
        color: $sidebar-menu-item-icon-color;
        font-weight: $font-weight-semibold;
      }

      li {
        a {
          display: block;
          padding: 0.625rem 1.5rem;
          color: $sidebar-menu-item-color;
          position: relative;
          font-size: 13px;
          transition: all 0.4s;
          text-decoration: none;

          span {
            margin-right: 8%;
          }

          &:hover {
            color: $sidebar-menu-item-hover-color;
            text-decoration: none;

            i {
              color: $sidebar-menu-item-hover-color;
            }
          }
        }

        .badge {
          margin-top: 4px;
        }

        ul.sub-menu {
          padding: 0;

          li {
            a {
              padding: 0.4rem 1.5rem 0.4rem 3.5rem;
              font-size: 13px;
              color: $sidebar-menu-sub-item-color;
              &:hover {
                color: $sidebar-menu-item-hover-color;
              }
            }

            ul.sub-menu {
              padding: 0;

              li {
                a {
                  padding: 0.4rem 1.5rem 0.4rem 4.5rem;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }

      li.mm-active {
        a.has-arrow,
        .nav--link--icon {
          color: white !important;
        }
      }
    }
  }
}

section.sidebar::-webkit-scrollbar {
  width: 0px !important;
}
