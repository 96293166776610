section.main {
  background-color: $dk_grey_4;

  .main--content {
    margin-left: $sidebar-width;
    overflow: hidden;
    padding-top: $topbar-height;

    &.collapsed {
      margin-left: $sidebar-width-collapsed;
    }
  }
}
