section.attendees-filter {
  .attendees-filter--user {
    white-space: nowrap;
    display: inline-block;
  }

  .attendees-filter--checkbox {
    display: none;
  }

  .attendees-filter--checkbox:checked + .attendees-filter--label {
    background-color: $dk-grey;
    border-radius: 50%;
  }

  .attendees-filter--photo {
    width: 3rem;
    border-radius: 50%;
    padding: 4%;
    cursor: pointer;
  }

  .attendees-filter--all__btn {
    border-radius: 5%;
    border-color: $dk-red;
    border-width: 1px;
    padding: 0;
    border: 0;
    background-color: $dk-red;
    line-height: 0;
    font-size: 80%;
    display: block !important;
    width: 100%;
    height: 60%;
    color: white;
  }
}
